<template>
  <div class="personal-result-view">
    <div class="result-main" v-loading="loading">
      <div class="main-top">
        <div class="content-left__wrap" v-if="date">
          <span class="content-left__time">{{ date | formateVal(1) }}</span>
          <i class="content-left__unit">{{ date | formateVal(2) }}</i>
        </div>
        <result-footer :list="levelList" :obj="{ name: name, count: count }" :levelData="levelData" model="B" />
        <div class="buttons">
          <div class="num" @click="showPassDialog = true">
            <i class="el-icon-sort"></i>
            <span class="refresh">调整本月S/A数量</span>
          </div>
          <div class="rank" @click="refreshRank">
            <i class="el-icon-refresh"></i>
            <span class="refresh">刷新排名</span>
          </div>
        </div>
      </div>
      <personal-item-card :lists="resultList" :max="max" @handleFlag="handleFlag" :benchmark="benchmark" :averageScore="parseFloat(averageScore)" />
    </div>
    <div class="result-bottom" @click="handleSubmit">
      <el-button type="primary" class="blue">提交考评</el-button>
    </div>
    <count-sel
      :showDialog="showPassDialog"
      :obj="{
        S1: this.Srange,
        A1: this.Arange, 
        S: levelList[0].big,
        A: levelList[1].big,
        count: count,
        C: levelList[3].big
      }"
      @resetTopdata="handleTopData"
    />
    <submit-list :code="code" :showDialog="showDialog" :userList="userList" />
  </div>
</template>
<script>
import resultFooter from '@/components/organizational/result-footer'
import personalItemCard from '@/components/organizational/personal-itemB-card.vue'
import { evalStaff, getEvalStaffTo, postEvalStaffSave } from '@/api/api_organization'
import countSel from '@/components/organizational/count-sel'
import { MessageBox, Message } from 'element-ui'
import submitList from '@/components/organizational/organization-submit-list'
import { scoreSum, formateNumber } from '@/utils/index.js'
import { sumMixin } from '@/mixins/organize/evaluationMixin'
export default {
  name: 'organize-evaluation',
  mixins: [sumMixin],
  components: {
    resultFooter,
    personalItemCard,
    countSel,
    submitList
  },
  data() {
    return {
      isShow: true
    }
  },
  methods: {
    //提交考评功能
    handleSubmit() {
      if (this.resultList.length < 1) {
        return false
      }
      if (this.resultList.length < 2) {
        this.isSort = true
      }
      if (!this.isSort) {
        MessageBox.confirm(`分数调整后尚未刷新排名，请先刷新排名`, '提示', {
          confirmButtonText: `确定`,
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.handleResetSort()
            this.isSort = true
          })
          .catch(() => {
            Message.info(`已取消操作`)
          })
      } else {
        // this.isShow = false
        this.handleResetSort()
        this.handleEvalOrgSave()
      }
    },
    handleEvalOrgSave(code = 1) {
      let orgSurplus = []
      this.levelList.forEach(el => {
        let org = { level: el.level, use: el.big }
        orgSurplus.push(org)
      })
      let params = {
        date: this.date,
        orgName: this.name,
        // orgId: this.orgId,
        id: this.id,
        surplus: orgSurplus, //等级结余
        partakeStaffs: this.resultList,
        benchmarkScore: this.benchmark ? this.averageScore : null,
        state: code //1 正常提交 99完成确认结果 9强制
      }
      console.log('提交考评params', params)
      postEvalStaffSave(params)
        .then(res => {
          if (res.code === '000000') {
            if (code === 1) {
              //第一次提交
              this.handleList()
              this.code = 99
            } else if (code === 99) {
              //第二次提交 确认提交
              this.$msgSuccess('个人绩效考评成功')
              this.showDialog = false
              this.orgList = []
              this.userList = []
              this.$router.push({ name: 'appraisal' })
            } else if (code === 9) {
              //  强制提交后，再次弹出确认提交
              this.handleList()
              this.code = 99
            }
          } else if (res.code === '051507') {
            //提交考评后数据有更新
            MessageBox.confirm(`部分成员的绩效总分后台有更新`, '提示', {
              confirmButtonText: `立即刷新排名`,
              cancelButtonText: '取消',
              type: 'warning'
            })
              .then(() => {
                this.getList()
                if (code === 99) {
                  this.showDialog = false
                }
              })
              .catch(() => {
                Message.info(`已取消操作`)
              })
          } else if (res.code === '051504') {
            //目标考评未完成
            MessageBox.confirm(`部分成员目标考评尚未完成，强制完成目标考评并继续?`, '提示', {
              confirmButtonText: `确定`,
              cancelButtonText: '取消',
              type: 'warning'
            })
              .then(() => {
                this.handleEvalOrgSave(9)
              })
              .catch(() => {
                Message.info(`已取消操作`)
              })
          } else {
            this.$msgError(res.msg || '接口异常')
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleList() {
      this.showDialog = true
      this.userList = []
      this.resultList.forEach(el => {
        let { uName, avatar, pfLevel, orgName } = el
        let user = {
          evalName: uName,
          evalAvatar: avatar,
          partakePfLevel: pfLevel,
          orgName
        }
        this.userList.push(user)
      })
    },
    handleTopData(S, A) {
      this.handleTopDataFn(S, A)
      this.handleResetSort()
    },
    /**
     *   列表
     */
    getList() {
      this.getTopOrgList().then(resTopData => {
        let {
          levels,
          targetMaxScore,
          benchmark: { rule, benchmarkScore }
        } = resTopData.data
        //rule 0 无限制 [1,2=平均分] [3,4=基准分]
        //benchmarkScore 基准分
        this.benchmark = rule
        this.benchmarkScore = benchmarkScore || 0
        let params = {
          date: this.date,
          orgId: this.orgId,
          eocId: this.cfgId,
          pocId: this.id
        }
        if (!this.benchmark) {
          this.benchmarkScore = null
        }
        this.loading = true
        getEvalStaffTo(params)
          .then(res => {
            this.count = (res.data && res.data.length) || 0
            //计算头部结余数据
            this.handleSum(levels, targetMaxScore)
            //排序+等级赋值
            this.resultList = res.data ? res.data : []
            this.handleResetSort()
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    handleResetSort() {
      //对个人排序 赋值
      //平均分/基准分
      // this.averageScore = this.handleAverageScore(
      //   this.benchmark,
      //   this.benchmarkScore,
      //   this.resultList
      // )
      this.averageScore = this.resultList[0] ? this.resultList[0].benchmarkScore : 0
      //extraScore 额外加减分==特别扣分
      this.resultList.forEach(el => {
        let { target = 0, rpmt = 0, evaluate = 0, satisfaction = 0, deduction = 0, innovate = 0, contribute = 0, orgpf = 0, extraScore = 0, customize1 = 0, customize2 = 0 } = el
        if (typeof target !== 'object') {
          if (target > this.max) {
            target = this.max
          }
        }
        let isCompute = this.benchmark && el.target < this.averageScore ? false : true
        if (!isCompute) {
          innovate = 0
          contribute = 0
        }
        let staffTotalScore = scoreSum(target, rpmt, evaluate, satisfaction, deduction, innovate, contribute, orgpf, extraScore, customize1, customize2)
        this.$set(el, 'staffTotalScore', formateNumber(staffTotalScore))
      })
      console.log(this.resultList, ' this.resultList')
      this.resultList.forEach(el => {
        if (el.sumOfTarget == 0) {
          this.resultList.sort(this.createComprisonFunction('staffTotalScore')) //总分
        } else {
          this.resultList.sort(this.createComprisonFunction('target')) //工作目标分
        }
      })
      this.levels = this.handleLevels(this.levelList[2].big)
      console.log('🚀 ~ file: personalEvaluation.vue:258 ~ handleResetSort ~ this.levels:', this.levelList, this.levels)
      this.isShow &&
        this.resultList.forEach((el, index) => {
          this.$set(el, 'pfLevel', this.levels[index])
        })
      console.log('列表数据', this.resultList)
    },
    /**
     * 刷新排名
     */
    refreshRank() {
      this.handleResetSort()
      this.isSort = true
    },
    /**
     * 头部百分比+上月结余信息
     * /eval/org
     */
    async getTopOrgList() {
      let params = {
        date: this.date,
        // orgId: this.orgId,
        pocId: this.id
      }
      return await evalStaff(params)
    }
  },
  created() {
    this.getList()
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/organize.scss';
</style>
