<template>
  <div class="result-infinite">
    <div class="result-box" v-for="(list, index) in lists" :key="index">
      <div class="personal-card-content">
        <div class="personal-item">
          <div @click="$router.push({ name: 'homepage-navigation', query: { userId: list.uId } })">
            <user-avatar :obj="{ avatar: list.avatar, name: list.uName }" />
          </div>
          <div class="box-left">
            <div class="title" @click="$router.push({ name: 'homepage-navigation', query: { userId: list.uId } })">
              {{ list.uName }}
            </div>
            <div class="content">{{ list.jobName || '暂无' }}</div>
          </div>
          <!-- {{ list.pfLevel }} -->
          <div v-if="isTojoy && (list.pfLevel == 'C' || list.pfLevel == 'D')" class="box-right" :class="'rank-' + list.pfLevel">
            <el-tooltip content="员工绩效为C后，支持绩效C/D等级进行切换" placement="bottom-end">
              <i class="el-icon-warning-outline" style="color:#3D7EFF"></i>
            </el-tooltip>
            <el-select size="small" v-model="list.pfLevel" class="box-right-select">
              <el-option label="C" value="C"></el-option>
              <el-option label="D" value="D"></el-option>
            </el-select>
          </div>
          <div v-else class="box-right" :class="'rank-' + list.pfLevel">{{ list.pfLevel }}</div>
        </div>
        <div class="personal-middle">
          <div class="middle-left" @click="handleUser(list)">
            <div class="num">
              {{ list.staffTotalScore | formateVal(list, benchmark, averageScore) }}分
              <!-- el-icon-caret-bottom -->
              <i class="el-icon-arrow-down"></i>
            </div>
            <div class="text">绩效总分</div>
          </div>
          <div class="middle-right">
            <div class="add">
              <el-input-number v-model="list.target" v-filterZero @change="handleChange" :min="0" :step="5" :max="max" size="mini"></el-input-number>
            </div>
            <div class="text">目标考评</div>
          </div>
        </div>
      </div>
    </div>
    <detail :showDialog="showDetailDialog" :userObj="userObj" :benchmark="benchmark" :averageScore="averageScore" />
  </div>
</template>

<script>
import detail from '@/components/organizational/detail'
import userAvatar from '@/components/initiate/user-avatar'
import { scoreSum, formateNumber } from '@/utils/index.js'
export default {
  name: 'personal-itemB-card',
  components: {
    detail,
    userAvatar
  },
  data() {
    return {
      isCompute: false,
      num: 1,
      userObj: {},
      showDetailDialog: false,
      isTojoy: JSON.parse(localStorage.getItem('isTojoy'))
    }
  },
  props: {
    lists: {
      type: Array,
      default: () => {}
    },
    max: {
      type: Number,
      default: 0
    },
    benchmark: {
      type: Number,
      default: 0
    },
    averageScore: {
      type: Number,
      default: 0
    }
  },
  computed: {
    avatar: {
      get() {
        return require('../../assets/images/profile.png')
      }
    }
  },
  filters: {
    formateVal(val, list, benchmark, averageScore) {
      let { target = 0, rpmt = 0, evaluate = 0, satisfaction = 0, deduction = 0, innovate = 0, contribute = 0, orgpf = 0, extraScore = 0 } = list
      let isCompute = benchmark && target < averageScore ? false : true
      if (!isCompute) {
        innovate = 0
        contribute = 0
      }
      let sum = scoreSum(target, rpmt, evaluate, satisfaction, deduction, innovate, contribute, orgpf, extraScore)
      return formateNumber(sum)
    }
  },
  methods: {
    errorHandler() {
      return true
    },
    handleUser(item) {
      this.showDetailDialog = true
      let { target, evaluate, orgpf, rpmt, satisfaction, deduction, innovate, contribute, extraScore, uName, customize1, customize2, customize1Name, customize2Name } = item
      this.userObj = {
        target,
        evaluate: evaluate,
        orgTotalScore: orgpf,
        rpmt,
        satisfaction,
        deduction,
        innovate,
        contribute,
        partakeExtraScore: extraScore,
        evalName: uName,
        customize1,
        customize2,
        customize1Name,
        customize2Name
      }
    },
    handleChange(value) {
      this.$emit('handleFlag', false)
      //目标分<平均分(基准分)
      // this.isCompute = this.benchmark && value < this.averageScore ? false : true
    }
  }
}
</script>

<style lang="scss" scoped>
.result-infinite {
  flex: 1;
  overflow-y: scroll;
  .nodata {
    font-size: 14px;
    font-weight: 400;
    color: $grey;
    line-height: 14px;
    text-align: center;
    margin-top: 30px;
  }
  .result-box {
    margin-left: 85px;
    padding: 20px 0px 20px 0px;
    box-shadow: 0px 1px 0px 0px #eeeeee;
    display: flex;
  }
}
.personal-card-content {
  width: 100%;
  .personal-item {
    display: flex;
    flex: 1;
    margin-bottom: 20px;
    margin-bottom: 20px;
    .box-left {
      margin-left: 10px;
      cursor: pointer;
      .title {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: $lightblack;
        line-height: 16px;
        margin-bottom: 7px;
      }
      .content {
        font-size: 12px;

        font-weight: 400;
        color: $darkgrey;
        line-height: 12px;
      }
      .bold {
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: $lightblack;
      }
      .grade {
        font-size: 12px;

        font-weight: 400;
        color: $darkgrey;
        line-height: 12px;
      }
    }
    .box-right {
      margin-right: 30px;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      text-align: center;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: $darkgrey;
      line-height: 18px;
      &-select {
        width: 64px;
        margin-left: 20px;
        /deep/.el-input__inner {
          color: $red;
        }
      }
    }
    .rank-C {
      color: $red !important;
    }
    .rank-S {
      color: $C89557 !important;
    }
    .rank-A {
      color: $blue !important;
    }
  }
  .personal-middle {
    display: flex;
    margin-left: 29px;
    align-items: center;
    .middle-left {
      text-align: center;
      margin-right: 60px;
      cursor: pointer;
      .num {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: $lightblack;
        line-height: 16px;
        .el-icon-caret-bottom {
          color: $blue;
        }
      }
      .text {
        margin-top: 15px;
        font-size: 12px;

        font-weight: 400;
        color: $grey;
        line-height: 12px;
      }
    }
    .middle-right {
      text-align: center;
      .add {
        width: 95px;
      }
      .text {
        margin-top: 10px;
        font-size: 12px;

        font-weight: 400;
        color: $grey;
        line-height: 12px;
      }
      /deep/.el-input-number--mini {
        width: 108px;
        box-sizing: border-box;
        height: 26px;
      }
      /deep/.el-input__inner {
        font-size: 16px;

        font-weight: 400;
        color: $lightblack;
        line-height: 16px;
        border-radius: 0px;
        box-sizing: border-box;
        border: 1px solid #aaaaaa;
      }
      /deep/.el-input-number__decrease,
      /deep/.el-input-number__increase {
        width: 26px;
        background: $white;
      }
      /deep/.el-input-number__increase {
        border-left: 1px solid #aaaaaa;
      }
      /deep/.el-input-number__decrease {
        border-right: 1px solid #aaaaaa;
      }
      /deep/.el-input-number--mini .el-input__inner {
        padding-left: 12px;
        padding-right: 12px;
      }
    }
  }
  .personal-bottom {
    margin-top: 15px;
    margin-right: 15px;
    height: 40px;
    background: #f6f6f6;
    border-radius: 3px;
    padding-left: 15px;
    display: flex;
    align-items: center;

    .bottom-left {
      display: flex;
      align-items: center;
      .bottom-img {
        width: 22px;
        height: 22px;
      }
      .text {
        margin-left: 5px;
        font-size: 12px;

        font-weight: 400;
        color: $lightblack;
        line-height: 12px;
      }
    }
    .bottom-right {
      margin-right: 15px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 12px;

      font-weight: 400;
      color: $darkgrey;
      line-height: 12px;
      .text {
        margin-right: 5px;
      }
      .grade {
        margin-left: 15px;
        margin-right: 10px;
      }
      .level {
        color: $blue;
      }
    }
  }
}
</style>
